@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ReadexProBold.ttf");
  font-family: "Readex Pro";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RasaBold.ttf");
  font-family: "Rasa";
  font-weight: 700;
}
